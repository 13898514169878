body {
    min-height: 100%;
}

.dataentry-padded {
    padding: $spacing5;
}

.dataentry-panel {
    border: 1px solid $gray-color-10;
    border-radius: $panel-border-radius;
    box-sizing: border-box;
    background: $white;

    &--fit-content {
        height: fit-content;
    }

    &--padded {
        padding: $spacing5;
    }

    &--half {
        width: 50%;
    }

    &--minimal {
        height: auto;
    }

    &--top-attachment {
        border-radius: 0 0 $panel-border-radius $panel-border-radius;
    }

    h2 {
        font-size: 19px;
        font-weight: 600;
        line-height: $spacing5;
        color: $gray-color-80;
    }

    .dataentry-panel__text {
        margin: $spacing5 0;
        color: $gray-color-60;
    }

    .dataentry-panel__error {
        margin: $spacing3 0;
        color: $error-color;
        font-weight: bold;
    }

    .dataentry-panel__old-dataentry-link {
        margin: $spacing3 0;
        color: $gray-color-60;
    }

    .dataentry-panel__standalone-button {
        margin-top: $spacing5;
    }
}

.dataentry-red-text {
    color: $error-color;
}
