.lg-dropdown.lg-dropdown--disabled {
    opacity: 0.65;
    color: black;

    .lg-dropdown__text.lg-dropdown__text--empty {
        color: black;
    }
}

.lg-scrollable__holder.flexcol .lgVirtualForOf-holder {
    flex-shrink: 0;
}

.lg-snackbar {
    margin-left: 76px;
}

.lg-styled-radio input[type="radio"]:disabled + span,
.lg-styled-radio input[type="radio"]:disabled:checked + span {
    color: $gray-color-25;
}
