.codman-tool-icon {
    background: transparent url("./../../logos/tool-icon-codman.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.nightingale-tool-icon {
    background: transparent url("./../../logos/data-platform.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.dataentry-tool-icon {
    background: transparent url("./../../logos/tool-icon-dataentry.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.data-connect-tool-icon {
    background: transparent url("./../../logos/tool-icon-data-connect.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.medicines-tool-icon {
    background: transparent url("./../../logos/tool-icon-medicines.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}
