/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";
@import "assets/styles/dataentry-styles";
body {
    --table-row-height: 40px !important;
    --major-color: #f15b04;
}

.lg-sidebar-btn__name.lg-sidebar-btn__name--menu {
    text-transform: uppercase;
}

.input,
textarea {
    &:disabled {
        opacity: 0.65;
    }
}

.button.button--tag {
    cursor: default;
    color: rgb(var(--gray-color-60));
    background-color: rgb(var(--button-background-disabled));
    box-shadow: none;
    font-weight: 500;
    pointer-events: none;
}

.button-group {
    display: flex;
    flex-direction: row;
    gap: calc($spacing0 / 2);
}

.button-group lg-button:not(:first-child) .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.button-group lg-button:not(:last-child) .button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.button-group .button--unselected {
    opacity: 0.5;
}

.lg-spinner {
    circle.path {
        stroke: $base-80;
    }
}
